import { render, staticRenderFns } from "./PushManager.vue?vue&type=template&id=c2b2a1d6&scoped=true"
import script from "./PushManager.vue?vue&type=script&lang=js"
export * from "./PushManager.vue?vue&type=script&lang=js"
import style0 from "./PushManager.vue?vue&type=style&index=0&id=c2b2a1d6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b2a1d6",
  null
  
)

export default component.exports